<template>
  <div>
    <div class="_BoxHeader">
      <span>题库维护</span>
    </div>
    <div class="PageContain">
      <div class="time-box">
        <el-date-picker
          v-model="time"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
        <div class="_Select">
          <span>可看人选：</span>
          <input
            class="input-name"
            type="text"
            v-model="userName"
            @input="searchPersons"
            placeholder="请输入名称"
          />
          <el-select
            ref="selectRef"
            multiple
            v-model="persons"
            :loading="loading"
            loading-text="加载中..."
            placeholder="请选择"
          >
            <el-option
              v-for="item in personList"
              :key="item.id"
              :label="item.userName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <el-button
          type="primary"
          @click="addItem"
          style="margin-left: 10px"
          v-if="isAdd"
          >确定添加</el-button
        >
        <el-button
          type="primary"
          @click="reqData"
          style="margin-left: 10px"
          v-if="!isAdd"
          >确定修改</el-button
        >
        <el-button @click="cancleModify" style="margin-left: 10px" v-if="!isAdd"
          >取消</el-button
        >
      </div>
      <span class="_Title">信息列表</span>
      <div style="padding: 10px; width: 98%">
        <el-table
          :data="timeList"
          stripe
          style="width: 100%"
          :header-cell-style="{
            background: '#E8EBF4',
            color: '#000000',
            'border-top': '1px solid #DFDFDF',
            'border-right': '1px solid #DFDFDF',
          }"
          border
          :height="elementHeight"
          id="table"
        >
          <el-table-column type="index" label="下标" width="80">
          </el-table-column>
          <el-table-column prop="name" label="开始时间">
            <template slot-scope="scope">
              <span>{{ scope.row.fromDate.split('T')[0] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="结束时间">
            <template slot-scope="scope">
              <span>{{ scope.row.toDate.split('T')[0] }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="dutyUserName" label="可看人选">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <i class="el-icon-edit" @click="editItem(scope.row)"></i>
              <i
                class="el-icon-delete"
                @click="deleteItem(scope.row, scope.$index)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put, deleteMethod } from '../../api/http'
export default {
  data() {
    return {
      elementHeight: 0,
      timeList: [],
      time: [],
      isAdd: true,
      rowItem: {},
      personList: [],
      userName: '',
      persons: [],
      loading: false,
      checkedlist: [],
      checkedPersons: [],
    }
  },
  mounted() {
    this.getElementHeight()
    this.getTimeList()
  },
  methods: {
    getElementHeight() {
      this.$nextTick(() => {
        this.elementHeight =
          window.innerHeight -
          (document.getElementById('table').offsetTop + 60 + 52 + 50)
        console.log(
          window.innerHeight,
          document.getElementById('table').offsetTop
        )
      })
    },
    async searchPersons() {
      if (!this.userName) return
      this.$refs.selectRef.toggleMenu()
      this.loading = true
      if (this.checkedlist.length != 0) {
        this.handleChecked()
      }
      await get('/api/User?UserNo=' + '&UserName=' + this.userName).then(
        (resp) => {
          if (resp.code == 200) {
            this.personList = JSON.parse(JSON.stringify(resp.data))
            this.checkedlist = resp.data
            this.loading = false
          }
        }
      )
    },
    handleChecked() {
      this.checkedlist.map((i) => {
        //当前搜索的数组
        this.persons.map((e) => {
          //当前所选中的人
          if (i.id == e) {
            this.checkedPersons.push({ id: i.id, userName: i.userName }) //合并所选中的人
          }
        })
      })
      this.checkedlist.length = []
    },
    getTimeList() {
      get('/api/TimeControl/GetList').then((resp) => {
        if (resp.code == 200) {
          this.timeList = resp.data
        }
      })
    },
    addItem() {
      this.isAdd = true
      this.reqData()
    },
    reqData() {
      if (this.time.length == 0) return
      if (this.checkedlist.length != 0) {
        this.handleChecked()
      }
      var userNameList = []
      var userIdList = []
      //选人：将之前所有选中的所有人和当前选中的所有人对比，没id的存在已删除，有id的就拿出去请求
      this.checkedPersons.map((i) => {
        this.persons.map((e) => {
          if (i.id == e) {
            userNameList.push(i.userName)
            userIdList.push(i.id)
          }
        })
      })
      var userName = Array.from(new Set(userNameList))
      var userId = Array.from(new Set(userIdList))
      var startTime = this.time[0]
      var endTime = this.time[1]
      var user = JSON.parse(sessionStorage.getItem('userInfo'))
      var data = {
        id: this.isAdd ? 0 : this.rowItem.id,
        fromYear: startTime.getFullYear(),
        fromMonth: startTime.getMonth() + 1,
        fromDay: startTime.getDate(),
        fromDate: startTime.toISOString(),
        toYear: endTime.getFullYear(),
        toMonth: endTime.getMonth() + 1,
        toDay: endTime.getDate(),
        toDate: endTime.toISOString(),
        dutyUserId: userId.toString(),
        dutyUserName: userName.toString(),
        saveUserId: user.id + '',
        saveUserNo: user.userNo,
        saveUserName: user.userName,
        saveDate: new Date().toISOString(),
      }
      var methods = this.isAdd ? post : put
      methods('/api/TimeControl', data)
        .then((resp) => {
          if (resp.code == 200) {
            this.time = []
            this.$message({
              type: 'success',
              message: this.isAdd ? '添加成功' : '修改成功',
            })
            this.isAdd = true
            this.getTimeList()
            this.userName = ''
            this.personList = []
            this.persons = []
          } else {
            this.$message.error(resp.message)
          }
        })
        .catch(() => {
          this.$message.error(this.isAdd ? '添加失败' : '修改失败')
        })
    },
    deleteItem(row, index) {
      this.$confirm('您确认要删除' + row.fromYear + '年的时间监控?')
        .then(() => {
          deleteMethod('/api/TimeControl?Id=' + row.id)
            .then((resp) => {
              if (resp.code == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功',
                })
                this.timeList.splice(index, 1)
              } else {
                this.$message.error(resp.message)
              }
            })
            .catch(() => {
              this.$message.error('删除失败')
            })
        })
        .catch(() => {})
    },
    editItem(row) {
      this.isAdd = false
      this.rowItem = row
      this.time = [new Date(row.fromDate), new Date(row.toDate)]
      if (row.dutyUserId == '') return
      var oneNameList = []
      // 获取每个姓氏，去查询获取数组来回显数据
      console.log(row.dutyUserName)
      if (!row.dutyUserName) return
      row.dutyUserName.split(',').map((i) => {
        oneNameList.push(i.charAt(0))
      })
      Array.from(new Set(oneNameList)).map((e) => {
        get('/api/User?UserNo=' + '&UserName=' + e).then((resp) => {
          if (resp.code == 200) {
            this.personList = this.personList.concat(
              JSON.parse(JSON.stringify(resp.data))
            )
            this.checkedlist = this.checkedlist.concat(resp.data)
          }
        })
      })
      row.dutyUserId.split(',').map((i) => {
        this.persons.push(Number(i))
      })
    },
    cancleModify() {
      this.isAdd = true
      this.time = []
    },
  },
}
</script>

<style lang="less" scoped>
.contain {
  padding-top: 20px;
  width: 300px;
}
.time-box {
  display: flex;
  align-items: center;
  margin: 15px;
  .time-item {
    display: flex;
    align-items: center;
    margin: 15px;
  }
}
.el-range-editor.el-input__inner {
  padding: 0 10px !important;
}
.el-icon-delete {
  color: red !important;
}
.el-icon-edit {
  color: blue !important;
}
.el-table {
  & i {
    border: 1px solid;
    border-radius: 50px;
    padding: 4px;
    margin: 0 20px;
  }
}
._Select {
  width: 500px !important;
  .el-select {
    width: 280px !important;
    /deep/.el-select__tags {
      overflow: hidden !important;
      flex-wrap: nowrap !important;
    }
  }
  .input-name {
    width: 110px !important;
    border: 1px solid #c0c0c0d5;
    border-radius: 5px;
    height: 28px !important;
    padding: 0 15px;
    line-height: 28px !important;
    font-size: 13px !important;
  }
  ::-webkit-input-placeholder {
    color: #c0c0c0fc;
  }
  :-moz-placeholder {
    color: #c0c0c0fc;
  }
  ::moz-placeholder {
    color: #c0c0c0fc;
  }
  :-ms-input-placeholder {
    color: #c0c0c0fc;
  }
}
</style>